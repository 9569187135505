import { configureStore } from "@reduxjs/toolkit";
import productionsReducer from "./productionsSlice";

export const store = configureStore({
    reducer: {
        productions: productionsReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
