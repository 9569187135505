"use client"

import { useState, useEffect } from "react"
import { Plus } from "lucide-react"
import { Button } from "./ui/Button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/Dialog"
import { Input } from "./ui/Input"
import { Label } from "./ui/Label"
import { useNavigate } from "react-router-dom"
import { Production, CrewRole } from "../types"
import { getProductions, createProduction } from "../services/apiService"
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentProduction, setProductions } from '../store/productionsSlice'; // import the action

// ProductionWithoutId now has roles as CrewRole[]
type ProductionWithoutId = Omit<Production, "id">

// Create an empty crew role (without id)
const emptyCrewRole: Omit<CrewRole, "id"> = {
  name: "",
  job: "",
  person: undefined,
  startDate: "",
  endDate: "",
  approximateDates: false,
  location: "",
  budget: 0,
  budgetFrequency: "Daily",
  description: "",
  status: "Hiring",
  department: "",
}

export default function ProductionsPage() {
  const [isNewProductionModalOpen, setIsNewProductionModalOpen] = useState(false)
  const [newProduction, setNewProduction] = useState<ProductionWithoutId>({
    name: "",
    company: "",
    roles: [],
  })
  // Initialize roles with one empty crew role (using emptyCrewRole)
  const [crewRoles, setCrewRoles] = useState<CrewRole[]>([
    { ...emptyCrewRole, id: "" }
  ])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [creating, setCreating] = useState<boolean>(false)
  const navigate = useNavigate()

  // Access productions from Redux store
  const productions = useSelector((state: any) => state.productions.productions);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProductions = async () => {
      try {
        setLoading(true)
        const data = await getProductions() // Fetch productions from API
        dispatch(setProductions(data)); // Dispatch to Redux store
      } catch (err) {
        console.error("Error fetching productions:", err)
        setError("Failed to load productions.")
      } finally {
        setLoading(false)
      }
    }

    fetchProductions()
  }, [dispatch]);

  const handleProductionChange = (field: string, value: string) => {
    setNewProduction((prev) => ({ ...prev, [field]: value }))
  }

  // When adding a new crew role, check that the last role's name is not empty
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddRole = () => {
    if (crewRoles[crewRoles.length - 1].name.trim() !== "") {
      setCrewRoles([
        ...crewRoles,
        { ...emptyCrewRole, id: "" }
      ]);
    }
  };
  
  const handleCreateProduction = async () => {
    if (newProduction.name.trim()) {
      setCreating(true)
      setError(null)

      try {
        const response: Production = await createProduction({
          name: newProduction.name,
          company: newProduction.company,
          // If needed, you could send crew roles along with the production.
          // For now, we're keeping the production creation separate.
        })

        console.log(response)

        if (response.id) {
          dispatch(setProductions([...productions, response])); // Update Redux store with new production
          dispatch(setCurrentProduction(response));
          resetForm()
          navigate(`/productions/${response.id}`)
        } else {
          setError("Failed to create production.")
        }
      } catch (err) {
        console.error("Error creating production:", err)
        setError("An error occurred while creating the production.")
      } finally {
        setCreating(false)
      }
    }
  }

  const handleViewProduction = (production: Production) => {
    console.log(production);
    dispatch(setCurrentProduction(production));
    navigate(`/productions/${production.id}`);
  };

  const resetForm = () => {
    setIsNewProductionModalOpen(false)
    setNewProduction({ name: "", company: "", roles: [] })
    setCrewRoles([{ ...emptyCrewRole, id: "" }])
  }

  return (
      <div className="min-h-screen bg-gray-100">
        <div className="p-8">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold">Productions</h1>

            <Dialog open={isNewProductionModalOpen} onOpenChange={setIsNewProductionModalOpen}>
              <DialogTrigger asChild>
                <Button>
                  <Plus className="mr-2 h-4 w-4" />
                  Add New Production
                </Button>
              </DialogTrigger>
              <DialogContent className="bg-white p-6 rounded-lg shadow-lg">
                <DialogHeader>
                  <DialogTitle>Create New Production</DialogTitle>
                  <DialogDescription>
                    Enter the details of the new production.
                  </DialogDescription>
                </DialogHeader>

                <div className="space-y-6 py-4">
                  <div className="grid grid-cols-2 gap-6">
                    <div className="space-y-2">
                      <Label htmlFor="name">Production Name</Label>
                      <Input
                          id="name"
                          value={newProduction.name}
                          onChange={(e) => handleProductionChange("name", e.target.value)}
                          placeholder="Enter production name"
                      />
                    </div>
                    <div className="space-y-2">
                      <Label htmlFor="company">Production Company</Label>
                      <Input
                          id="company"
                          value={newProduction.company}
                          onChange={(e) => handleProductionChange("company", e.target.value)}
                          placeholder="Enter company name (optional)"
                      />
                    </div>
                  </div>

                  {error && <p className="text-red-500">{error}</p>}

                  <div className="flex justify-end gap-3 pt-4">
                    <Button variant="outline" onClick={resetForm}>
                      Cancel
                    </Button>
                    <Button onClick={handleCreateProduction} disabled={creating}>
                      {creating ? "Creating..." : "Create Production"}
                    </Button>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>

          <div className="grid gap-4">
            {loading ? (
                <p>Loading productions...</p>
            ) : productions.length === 0 ? (
                <div className="block bg-white p-6 rounded-lg shadow text-center text-gray-500">
                  No productions yet. Click "Add New Production" to get started.
                </div>
            ) : (
                productions.map((production: Production) => (
                    <div key={production.id} className="bg-white p-6 rounded-lg shadow">
                      <h2 className="text-xl font-semibold mb-2">{production.name}</h2>
                      <p className="text-gray-600 mb-4">{production.company}</p>
                      <Button onClick={() => handleViewProduction(production)}>View Details</Button>
                    </div>
                ))
            )}
          </div>
        </div>
      </div>
  )
}
