import axios from "axios";
import { setSession } from "../components/utils";

// const BASE_URL = "http://localhost:5147";
const BASE_URL = "https://wf0zk57v42.execute-api.ap-southeast-2.amazonaws.com/prod";

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
});

// Load token from sessionStorage on app start
const token = sessionStorage.getItem("authToken");
if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

// Response interceptor to handle token expiry
axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error.response || {};
        if (status === 401) {
            // Unauthorized, clear session and redirect
            if (sessionStorage.getItem("authToken")) {
                setSession(null);
                window.location.href = "/login";
            }
        }
        return Promise.reject(
            (error.response && error.response.data) || "Something went wrong"
        );
    }
);

export default axiosInstance;
