import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/auth/auth-provider';
import { LoginForm } from './components/auth/login-form';
import RootRedirect from './components/auth/root-redirect';
import LogoutComponent from './components/auth/logout-component';
import { SignupForm } from './components/auth/signup-form';
import { VerifyAccountForm } from './components/auth/verify-form';
import ProtectedRoute from './components/protected-route';
import Productions from './components/Productions';
import ProductionPage from './components/ProductionPage';
import "./styles/globals.css";

function Header() {
    const { isAuthenticated, user, logout } = useAuth();
    const navigate = useNavigate();

    return (
        <header className="flex p-4 shadow-md">
            <div className="flex gap-4">
                {isAuthenticated && user ? (
                    <>
                        <span className="text-sm">Logged in as {user.email}</span>
                        <button
                            onClick={() => {
                                logout();
                                navigate("/login"); // ✅ Redirect after logout
                            }}
                            className="text-sm underline"
                        >
                            Logout
                        </button>
                    </>
                ) : (
                    <span className="text-sm text-gray-500"></span>
                )}
            </div>
        </header>
    );
}

function App() {
    return (
        <AuthProvider>
            <Router>
                <div className="flex min-h-screen w-full items-center justify-center p-6 md:p-10">
                    <div className="w-full">
                        <Header /> {/* 👈 Add this component here */}
                        <Routes>
                            <Route path="/login" element={<LoginForm />} />
                            <Route path="/logout" element={<LogoutComponent />} />
                            <Route path="/signup" element={<SignupForm />} />
                            <Route path="/verify-account" element={<VerifyAccountForm />} />
                            <Route path="/productions" element={<ProtectedRoute component={Productions} />} />
                            <Route path="/productions/:id" element={<ProtectedRoute component={ProductionPage} />} />
                            {/* Redirect based on auth status */}
                            <Route path="*" element={<RootRedirect />} />
                        </Routes>
                    </div>
                </div>
            </Router>
        </AuthProvider>
    );
}

export default App;
