"use client";

import React from "react";
import { formatDateForInput } from "../utils/dateUtils";
import { Edit, Trash, MoreVertical } from "lucide-react";
import { Button } from "./ui/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./ui/Table";
import { Badge } from "./ui/Badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/DropdownMenu";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/Popover";

import { Contact, CrewRole } from "../types";

interface CrewRolesTableProps {
  roles: CrewRole[];
  onEditRole: (role: CrewRole) => void;
  onDeleteRole: (roleId: string) => void;
  openContactId: string | null;
  setOpenContactId: (id: string | null) => void;
  onAssignContact: (roleId: string, contact: Contact) => void;
}

export function CrewRolesTable({
                          roles,
                          onEditRole,
                          onDeleteRole,
                          openContactId,
                          setOpenContactId,
                          onAssignContact,
                        }: CrewRolesTableProps) {
  console.log(roles);
  return (
      <div className="overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Person</TableHead>
              <TableHead>Dates</TableHead>
              <TableHead>Location</TableHead>
              <TableHead>Budget</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>{/* Actions column */}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {roles.map((role) => (
                <TableRow key={role.id}>
                  <TableCell>{role.name}</TableCell>
                  <TableCell>
                    <Popover
                        open={openContactId === role.id}
                        onOpenChange={(isOpen) => setOpenContactId(role.id!)}
                        // onOpenChange={(isOpen) => setOpenContactId(isOpen ? role.id : null)}
                    >
                      <PopoverTrigger asChild>
                        <Button variant="link" className="p-0 h-auto font-normal">
                          {role.person?.name || "Not assigned"}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-[200px] p-0">
                        {/* Contact assignment UI (e.g. a command palette or list) can be added here */}
                      </PopoverContent>
                    </Popover>
                  </TableCell>
                  <TableCell>
                    {role.startDate && role.endDate
                        ? `${formatDateForInput(role.startDate)} - ${formatDateForInput(role.endDate)}`
                        : "TBD"}
                    {role.approximateDates && " (Approx.)"}
                  </TableCell>
                  <TableCell>{role.location}</TableCell>
                  <TableCell>
                    {role.budget >= 0 ? `$${role.budget} ${role.budgetFrequency}` : "-"}
                  </TableCell>
                  <TableCell>
                    <Badge
                        variant={
                          role.status === "Hiring"
                              ? "destructive"
                              : role.status === "Booked"
                                  ? "default"
                                  : "secondary"
                        }
                    >
                      {role.status}
                    </Badge>
                  </TableCell>
                  <TableCell>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" className="h-8 w-8 p-0">
                          <span className="sr-only">Open menu</span>
                          <MoreVertical className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuItem onClick={() => {
                          // Ensure we pass the original role data without modification
                          onEditRole({
                            ...role,
                            startDate: role.startDate || '',
                            endDate: role.endDate || ''
                          });
                        }}>
                          <Edit className="mr-2 h-4 w-4" />
                          <span>Edit</span>
                        </DropdownMenuItem>
                        <DropdownMenuItem onClick={() => onDeleteRole(role.id!)}>
                          <Trash className="mr-2 h-4 w-4" />
                          <span>Delete</span>
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
  );
}