import axios from "../services/axios";

function jwtDecode(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
            .join('')
    );

    return JSON.parse(jsonPayload);
}

export const isValidToken = (accessToken: string) => {
    if (!accessToken) {
        return false;
    }

    const decoded = jwtDecode(accessToken);

    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
};

export const tokenExpired = (exp: number) => {
    // eslint-disable-next-line prefer-const
    let expiredTimer;

    const currentTime = Date.now();

    // Test token expires after 10s
    // const timeLeft = currentTime + 10000 - currentTime; // ~10s
    const timeLeft = exp * 1000 - currentTime;

    clearTimeout(expiredTimer);

    expiredTimer = setTimeout(() => {
        alert('Token expired');

        sessionStorage.removeItem('authToken');

        window.location.href = '/';
    }, timeLeft);
};

let expiredTimer: ReturnType<typeof setTimeout> | null = null;

export const setSession = (accessToken: string | null) => {
    if (accessToken && isValidToken(accessToken)) {
        sessionStorage.setItem("authToken", accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        const { exp } = jwtDecode(accessToken);
        if (expiredTimer) clearTimeout(expiredTimer); // Prevent multiple timers

        expiredTimer = setTimeout(() => {
            alert("Token expired. Please log in again.");
            setSession(null);
            window.location.href = "/login";
        }, exp * 1000 - Date.now());
    } else {
        sessionStorage.removeItem("authToken");
        delete axios.defaults.headers.common.Authorization;
        if (expiredTimer) clearTimeout(expiredTimer);
    }
};
