import React, { createContext, useContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { setSession } from "../utils";

interface User {
    id: string;
    name: string;
    email: string;
}

interface AuthContextType {
    login: (token: string) => void;
    logout: () => void;
    isAuthenticated: boolean;
    user: User | null;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!sessionStorage.getItem("accessToken"));
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        const token = sessionStorage.getItem("accessToken");
        if (token) {
            try {
                const decoded: any = jwtDecode(token); // Decode JWT
                setUser({
                    id: decoded.sub, // Assuming the token contains `sub` as user ID
                    name: decoded.name,
                    email: decoded.email,
                });
            } catch (error) {
                console.error("Error decoding token:", error);
                logout();
            }
        } else {
            // Ensure user is cleared if no token exists
            setUser(null);
            setIsAuthenticated(false);
        }
    }, [isAuthenticated]); // Re-run when authentication state changes

    const login = (token: string) => {
        setSession(token); 
        sessionStorage.setItem("accessToken", token);
        setIsAuthenticated(true);

        try {
            const decoded: any = jwtDecode(token);
            setUser({
                id: decoded.sub,
                name: decoded.name,
                email: decoded.email,
            });
        } catch (error) {
            console.error("Error decoding token:", error);
            logout();
        }
    };

    const logout = () => {
        setSession(null); // Clear token and headers
        sessionStorage.removeItem("accessToken");
        setIsAuthenticated(false);
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ login, logout, isAuthenticated, user }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};
