import { CrewRole } from "../types";

export interface ValidationError {
  field: string;
  message: string;
}

export function validateRole(role: Partial<CrewRole>): ValidationError[] {
  const errors: ValidationError[] = [];

  if (!role.name?.trim()) {
    errors.push({ field: "name", message: "Job title is required" });
  }

  if (!role.status) {
    errors.push({ field: "status", message: "Status is required" });
  }

  if (!role.startDate) {
    errors.push({ field: "startDate", message: "Start date is required" });
  }

  if (!role.endDate) {
    errors.push({ field: "endDate", message: "End date is required" });
  }

  if (role.startDate && role.endDate && new Date(role.startDate) > new Date(role.endDate)) {
    errors.push({ field: "endDate", message: "End date must be after start date" });
  }

  if (!role.location?.trim()) {
    errors.push({ field: "location", message: "Location is required" });
  }

  if (!role.budget || role.budget <= 0) {
    errors.push({ field: "budget", message: "A valid budget amount is required" });
  }

  if (!role.department?.trim()) {
    errors.push({ field: "department", message: "Department is required" });
  }

  if (role.budget !== undefined && !role.budgetFrequency) {
    errors.push({ field: "budgetFrequency", message: "Budget frequency is required when budget is specified" });
  }

  if ((role.status === "Booked" || role.status === "Confirmed") && !role.person?.name) {
    errors.push({ field: "personName", message: "Person name is required for booked/confirmed roles" });
  }

  return errors;
}