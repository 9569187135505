import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Production } from "../types";
import { getProductions, getProductionById, UpdateProductionResponse, UpdateProductionRequest, updateProduction } from "../services/apiService";

// Define state type
interface ProductionsState {
    productions: Production[];
    currentProduction: Production | null;
    loading: boolean;
    error: string | null;
}

// Initial state
const initialState: ProductionsState = {
    productions: [],
    currentProduction: null,
    loading: false,
    error: null,
};

// Async thunk to fetch all productions
export const fetchProductions = createAsyncThunk("productions/fetchProductions", async () => {
    return await getProductions();
});

// Async thunk to fetch a single production by ID
export const fetchProductionById = createAsyncThunk("productions/fetchProductionById", async (id: string) => {
    return await getProductionById(id);
});

const productionsSlice = createSlice({
    name: "productions",
    initialState,
    reducers: {
        setProductions: (state, action: PayloadAction<Production[]>) => {
            state.productions = action.payload;
        },
        setCurrentProduction: (state, action: PayloadAction<Production | null>) => {
            state.currentProduction = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchProductions.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchProductions.fulfilled, (state, action) => {
            state.loading = false;
            state.productions = action.payload;
        })
        .addCase(fetchProductions.rejected, (state) => {
            state.loading = false;
            state.error = "Failed to load productions.";
        })
        .addCase(fetchProductionById.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchProductionById.fulfilled, (state, action) => {
            state.loading = false;
            state.currentProduction = action.payload;
        })
        .addCase(fetchProductionById.rejected, (state) => {
            state.loading = false;
            state.error = "Failed to load production details.";
        })
        .addCase(updateProductionThunk.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(updateProductionThunk.fulfilled, (state, action) => {
            state.loading = false;
            // Update the current production in state
            state.currentProduction = {
                id: action.payload.id,
                name: action.payload.name,
                company: action.payload.company,
                roles: action.payload.roles,  // adjust if your Production type differs
            } as Production;
            // Optionally, update the production in the productions list:
            state.productions = state.productions.map((prod) =>
                prod.id === action.payload.id ? state.currentProduction! : prod
            );
        })
        .addCase(updateProductionThunk.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as string;
        });
        ;
    },
});

export const updateProductionThunk = createAsyncThunk<
    UpdateProductionResponse,
    UpdateProductionRequest
>(
    'productions/updateProduction',
    async (updateData, { rejectWithValue }) => {
        try {
            const payload = {
                productionId: updateData.productionId,
                roles: (updateData.roles).map(role => ({
                    id: role.id,
                    name: role.name,
                    department: role.department,
                    location: role.location || "",
                    budget: Number(role.budget ?? 0), 
                    budgetFrequency: role.budgetFrequency,
                    approximateDates: role.approximateDates,
                    description: role.description,
                    status: role.status,
                    startDate: role.startDate,
                    endDate: role.endDate
                }))
            };

            const data = await updateProduction(payload);
            return data;
        } catch (error: any) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Export actions
export const { setProductions, setCurrentProduction } = productionsSlice.actions;

// Export reducer
export default productionsSlice.reducer;
