"use client";

import React, { useState } from "react";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";
import { Button } from "../ui/Button";
import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import {Link, useNavigate} from "react-router-dom";
import { signup } from "../../services/apiService";
import { cn } from "../../lib/utils";

export function SignupForm({ className, ...props }: React.ComponentPropsWithoutRef<"div">) {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: "",
        adminEmail: "",
        adminFirstName: "",
        adminLastName: "",
        password: "",
    });

    const [errors, setErrors] = useState({
        name: "",
        adminEmail: "",
        adminFirstName: "",
        adminLastName: "",
        password: "",
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setFormData((prev) => ({ ...prev, [id]: value }));

        // Clear the error for the current field when the user starts typing
        setErrors((prev) => ({ ...prev, [id]: "" }));
    };

    const validateForm = () => {
        const newErrors = {
            name: "",
            adminEmail: "",
            adminFirstName: "",
            adminLastName: "",
            password: "",
        };

        if (!formData.adminFirstName) newErrors.adminFirstName = "First name is required.";
        if (!formData.adminLastName) newErrors.adminLastName = "Last name is required.";
        if (!formData.adminEmail) {
            newErrors.adminEmail = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.adminEmail)) {
            newErrors.adminEmail = "Invalid email address.";
        }
        if (!formData.name) newErrors.name = "Organisation name is required.";
        if (!formData.password) newErrors.password = "Password is required.";
        else if (formData.password.length < 6) newErrors.password = "Password must be at least 6 characters long.";

        setErrors(newErrors);

        return Object.values(newErrors).every((error) => error === "");
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!validateForm()) return;

        try {
            setLoading(true);

            const response = await signup(formData);

            if (response.success) {
                navigate("/verify-account"); // Redirect to verify account page
            } else {
                setErrors((prev) => ({
                    ...prev,
                    form: response.message || "Something went wrong.",
                }));
            }
        } catch (error) {
            setErrors((prev) => ({
                ...prev,
                form: "Failed to create account. Please try again later.",
            }));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={cn("flex flex-col gap-6", className)} {...props}>
            <Card>
                <CardHeader>
                    <CardTitle>Signup</CardTitle>
                </CardHeader>
                <CardContent>
                    <form className="space-y-4" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                            <div>
                                <Label htmlFor="adminFirstName">First Name</Label>
                                <Input
                                    id="adminFirstName"
                                    placeholder="John"
                                    value={formData.adminFirstName}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.adminFirstName && (
                                    <p className="text-red-500 text-sm mt-1">{errors.adminFirstName}</p>
                                )}
                            </div>
                            <div>
                                <Label htmlFor="adminLastName">Last Name</Label>
                                <Input
                                    id="adminLastName"
                                    placeholder="Doe"
                                    value={formData.adminLastName}
                                    onChange={handleChange}
                                    required
                                />
                                {errors.adminLastName && (
                                    <p className="text-red-500 text-sm mt-1">{errors.adminLastName}</p>
                                )}
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="adminEmail">Email Address</Label>
                            <Input
                                id="adminEmail"
                                type="email"
                                placeholder="admin@example.com"
                                value={formData.adminEmail}
                                onChange={handleChange}
                                required
                            />
                            {errors.adminEmail && (
                                <p className="text-red-500 text-sm mt-1">{errors.adminEmail}</p>
                            )}
                        </div>
                        <div>
                            <Label htmlFor="password">Password</Label>
                            <Input
                                id="password"
                                type="password"
                                placeholder="Enter your password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                            {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}
                        </div>
                        <div>
                            <Label htmlFor="name">Organisation Name</Label>
                            <Input
                                id="name"
                                placeholder="ScreenTeam Inc."
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                        </div>
                        {errors && (
                            <p className="text-red-500 text-sm text-center mt-2">{errors.name}</p>
                        )}
                        <Button type="submit" disabled={loading} className="w-full">
                            {loading ? "Creating Account..." : "Sign Up"}
                        </Button>
                        <div className="mt-4 text-center text-sm">
                            Already have an account? &nbsp;
                            <Link
                                to="#"
                                onClick={() => navigate("/login")}
                                className="underline underline-offset-4"
                            >
                                Login
                            </Link>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}
