import React from 'react';
import { Navigate } from 'react-router-dom';

// Mock authentication function; replace with your auth logic
const isAuthenticated = () => !!sessionStorage.getItem('authToken');

const ProtectedRoute = ({ component: Component }: { component: React.ComponentType }) => {
    return isAuthenticated() ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
