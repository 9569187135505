import { useEffect } from "react";
import { logout } from "../../services/apiService";
import { useAuth } from "./auth-provider";

export default function Logout() {
    const { logout: authLogout } = useAuth();
    
    useEffect(() => {
        const handleLogout = async () => {
            try {
                await logout();
                authLogout(); // Call the AuthContext logout function
            } catch (error) {
                console.error("Logout failed", error);
            } finally {
                window.location.href = "/login";
            }
        };

        handleLogout();
    }, [authLogout]);

    return <p>Logging out...</p>;
}
