"use client";

import React, { useState, useEffect } from "react";
import { ArrowLeft, Plus } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "./ui/Button";


import { fetchProductionById, updateProductionThunk } from "../store/productionsSlice";
import { AppDispatch, RootState } from "../store/store";
import { Contact, CrewRole, Production } from "../types";
import { DetailedRoleModal } from "./DetailedRoleModal";
import { CrewRolesTable } from "./RolesTable";
import { FilterBar } from "./FilterBar";
import { ProductionDetails } from "./ProductionDetails";

const emptyRole: Omit<CrewRole, "id"> = {
  name: "",
  job: "",
  person: undefined,
  startDate: "",
  endDate: "",
  approximateDates: false,
  location: "",
  budget: 0,
  budgetFrequency: "Daily",
  description: "",
  status: "Hiring",
  department: "",
};

const departments = [
  "Above the line (Producer, Directors, Financiers)",
  "Production",
  "AD's",
  "Camera Dept",
  "Second Unit",
  "Sound",
  "Grip",
  "Lighting",
  "Costume",
  "HMU (Hair & Make Up)",
  "Art Dept",
  "Construction",
  "Stunts",
  "Safety",
  "Location",
  "Transport",
  "Catering",
  "Armourer",
  "Animals Wranglers",
  "Special FX",
  "Unit",
];

export default function ProductionPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { currentProduction } = useSelector((state: RootState) => state.productions) || {};

  // State
  const [production, setProduction] = useState<Production | null>(null);
  const [crewRoles, setCrewRoles] = useState<CrewRole[]>([]);
  const [isDetailedModalOpen, setIsDetailedModalOpen] = useState(false);
  // const [isMultipleRolesModalOpen, setIsMultipleRolesModalOpen] = useState(false);
  // const [isMultipleRolesModalOpen, setIsMultipleRolesModalOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState<CrewRole["status"] | "All">("All");
  const [newRole, setNewRole] = useState<Omit<CrewRole, "id">>(emptyRole);
  const [editingRoleId, setEditingRoleId] = useState<string | null>(null);
  // const [multipleRoles, setMultipleRoles] = useState<{ id: string; job: string }[]>([
  //   { id: "", job: "" },
  // ]);
  const [openContactId, setOpenContactId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState("");

  // Fetch production on mount
  useEffect(() => {
    if (id) {
      dispatch(fetchProductionById(id));
    }
  }, [id, dispatch]);

  // Sync production data when available
  useEffect(() => {
    if (currentProduction) {
      // Uncomment and adjust the following if your Redux production includes roles:
      setCrewRoles(currentProduction.roles);
      setProduction(currentProduction);
    }
  }, [currentProduction]);

  // Save updated roles to localStorage (or your persistence layer)
  const updateProductionInStorage = (updatedRoles: CrewRole[]) => {
    const savedProductions = localStorage.getItem("productions");
    if (savedProductions && production) {
      const productions: Production[] = JSON.parse(savedProductions);
      const updatedProductions = productions.map((p) =>
          p.id === production.id ? { ...p, roles: updatedRoles } : p
      );
      localStorage.setItem("productions", JSON.stringify(updatedProductions));
      setProduction({ ...production, roles: updatedRoles });
      setCrewRoles(updatedRoles);
    }
  };

  // Detailed role modal submit handler
  const handleDetailedSubmit = () => {
    if (newRole.name.trim()) {
      let updatedRoles: CrewRole[];
      const validBudgetFrequencies = ["Hourly", "Daily", "Weekly"]; // ✅ Matches backend enum

      if (editingRoleId) {
        updatedRoles = crewRoles.map((role) =>
            role.id === editingRoleId
                ? {
                  ...newRole,
                  id: editingRoleId,
                  department: newRole.department ?? "",
                  location: newRole.location ?? "",
                  budgetFrequency: validBudgetFrequencies.includes(newRole.budgetFrequency)
                      ? newRole.budgetFrequency
                      : "Daily", // ✅ Default to "PerDay" if invalid
                  person: newRole.person
                      ? { ...newRole.person, name: newRole.person.name }
                      : undefined,
                }
                : role
        );
      } else {
        const roleToAdd: CrewRole = {
          ...newRole,
          id: undefined,
          department: newRole.department ?? "",
          location: newRole.location ?? "",
          budgetFrequency: validBudgetFrequencies.includes(newRole.budgetFrequency)
              ? newRole.budgetFrequency
              : "Daily", // ✅ Default to "PerDay" if invalid
          person: newRole.person
              ? { ...newRole.person, name: newRole.person.name }
              : undefined,
        };
        updatedRoles = [...crewRoles, roleToAdd];
      }

      dispatch(updateProductionThunk({ productionId: currentProduction?.id || "", roles: updatedRoles }));
      setCrewRoles(updatedRoles);
      setNewRole(emptyRole);
      setIsDetailedModalOpen(false);
      setEditingRoleId(null);
    }
  };

  // Open modal for editing a role
  const handleEditRole = (role: CrewRole) => {
    setNewRole(role);
    setEditingRoleId(role.id!);
    setIsDetailedModalOpen(true);
  };

  // Delete a role
  const handleDeleteRole = (roleId: string) => {
    const updatedRoles = crewRoles.filter((role) => role.id !== roleId);
    setCrewRoles(updatedRoles);
    updateProductionInStorage(updatedRoles);
  };

  // Multiple roles modal handlers
  // const handleAddMultipleRole = () => {
  //   if (multipleRoles[multipleRoles.length - 1].job.trim() !== "") {
  //     setMultipleRoles([...multipleRoles, { id: "", job: "" }]);
  //   }
  // };

  // const handleMultipleRoleChange = (id: string, value: string) => {
  //   const newRoles = multipleRoles.map((role) =>
  //       role.id === id ? { ...role, job: value } : role
  //   );
  //   setMultipleRoles(newRoles);
  // };

  // const handleRemoveMultipleRole = (id: string) => {
  //   const newRoles = multipleRoles.filter((role) => role.id !== id);
  //   setMultipleRoles(newRoles);
  // };

  // const handleMultipleRolesSubmit = () => {
  //   const newRoles = multipleRoles
  //       .filter((role) => role.job.trim() !== "")
  //       .map((role) => ({
  //         ...emptyRole,
  //         id: Date.now() + role.id,
  //         job: role.job,
  //       }));
  //   const updatedRoles = [...crewRoles, ...newRoles];
  //   setCrewRoles(updatedRoles);
  //   updateProductionInStorage(updatedRoles);
  //   setMultipleRoles([{ id: "", job: "" }]);
  //   setIsMultipleRolesModalOpen(false);
  // };

  // Filter the crew roles based on status and search query
  const filteredRoles = crewRoles.filter(
      (role) =>
          (statusFilter === "All" || role.status === statusFilter) &&
          (role.job ?? "").toLowerCase().includes(searchQuery.toLowerCase())
  );


  // (Optional) Handler for assigning a contact to a role
  const handleAssignContact = (roleId: string, contact: Contact) => {
    const updatedRoles = crewRoles.map((role) =>
        role.id === roleId
            ? {
              ...role,
              person: { name: contact.name, email: "", phone: "" },
              status: "Booked" as const,
            }
            : role
    );
    setCrewRoles(updatedRoles);
    updateProductionInStorage(updatedRoles);
    setOpenContactId(null);
  };

  if (!currentProduction) {
    return <div>Loading...</div>;
  }

  return (
      <div className="min-h-screen bg-gray-100">
        <div className="p-8">
          <Button variant="outline" onClick={() => navigate("/productions")} className="mb-4">
            <ArrowLeft className="mr-2 h-4 w-4" />
            Back to Productions
          </Button>
          <ProductionDetails production={currentProduction} />
          <div className="flex justify-between items-center mb-4">
            <h4 className="text-xl font-semibold">Crew Roles</h4>
            <div className="space-x-2">
              <Button variant="outline" onClick={() => {
                setNewRole(emptyRole);
                setEditingRoleId(null);
                setIsDetailedModalOpen(true);
              }}>
                <Plus className="mr-2 h-4 w-4" />
                Add Single Role
              </Button>
              {/*<MultipleRolesModal*/}
              {/*    isOpen={isMultipleRolesModalOpen}*/}
              {/*    setIsOpen={setIsMultipleRolesModalOpen}*/}
              {/*    multipleRoles={multipleRoles}*/}
              {/*    onAddRole={handleAddMultipleRole}*/}
              {/*    onRoleChange={handleMultipleRoleChange}*/}
              {/*    onRemoveRole={handleRemoveMultipleRole}*/}
              {/*    onSubmit={handleMultipleRolesSubmit}*/}
              {/*/>*/}
              <DetailedRoleModal
                  isOpen={isDetailedModalOpen}
                  setIsOpen={setIsDetailedModalOpen}
                  newRole={newRole}
                  setNewRole={setNewRole}
                  editingRoleId={editingRoleId}
                  onSubmit={handleDetailedSubmit}
                  onCancel={() => {
                    setIsDetailedModalOpen(false);
                    setNewRole(emptyRole);
                    setEditingRoleId(null);
                  }}
                  departments={departments}
              />
            </div>
          </div>
          <FilterBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              statusFilter={statusFilter}
              setStatusFilter={setStatusFilter}
          />
          <CrewRolesTable
              roles={filteredRoles}
              onEditRole={handleEditRole}
              onDeleteRole={handleDeleteRole}
              openContactId={openContactId}
              setOpenContactId={setOpenContactId}
              onAssignContact={handleAssignContact}
          />
        </div>
      </div>
  );
}
