import { cn } from "../../lib/utils";
import { Button } from "../ui/Button";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "../ui/Card";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";
import {Link, useNavigate} from "react-router-dom";
import { verifyAccount } from "../../services/apiService";
import { useState } from "react";

export function VerifyAccountForm({ className, ...props }: React.ComponentPropsWithoutRef<"div">) {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [tempPassword, setTempPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [error, setError] = useState("");

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const success = await verifyAccount({ username, tempPassword, newPassword });
            if (success) {
                navigate("/dashboard"); // Redirect to login after successful verification
            } else {
                setError("Account verification failed. Please try again.");
            }
        } catch (err: any) {
            setError(err.message || "An unexpected error occurred.");
        }
    };

    return (
        <div className={cn("flex flex-col gap-6", className)} {...props}>
            <Card>
                <CardHeader>
                    <CardTitle className="text-2xl">Verify Account</CardTitle>
                    <CardDescription>
                        Enter your temporary password and set a new password
                    </CardDescription>
                </CardHeader>
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col gap-6">
                            <div className="grid gap-2">
                                <Label htmlFor="username">Username</Label>
                                <Input
                                    id="username"
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Username"
                                    required
                                />
                            </div>
                            <div className="grid gap-2">
                                <Label htmlFor="tempPassword">Temporary Password</Label>
                                <Input
                                    id="tempPassword"
                                    type="password"
                                    value={tempPassword}
                                    onChange={(e) => setTempPassword(e.target.value)}
                                    placeholder="Temporary Password"
                                    required
                                />
                            </div>
                            <div className="grid gap-2">
                                <Label htmlFor="newPassword">New Password</Label>
                                <Input
                                    id="newPassword"
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="New Password"
                                    required
                                />
                            </div>
                            <Button type="submit" className="w-full">
                                Verify Account
                            </Button>

                            {error && <p className="text-red-500">{error}</p>}
                        </div>
                        <div className="mt-4 text-center text-sm">
                            Already verified? {" "}
                            <Link
                                to="#"
                                onClick={() => navigate("/login")}
                                className="underline underline-offset-4"
                            >
                                Login here
                            </Link>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}
