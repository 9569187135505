import { Production } from "../types";

export function ProductionDetails({ production }: { production: Production }) {
  return (
      <div className="bg-white p-6 rounded-lg shadow-md mb-6">
        <h2 className="text-2xl font-bold mb-4">{production.name}</h2>
        {production.company && (
            <p className="text-gray-600">Production Company: {production.company}</p>
        )}
      </div>
  );
}