"use client";

import React from "react";
import { Filter } from "lucide-react";
import { Button } from "./ui/Button";
import { Input } from "./ui/Input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/DropdownMenu";
import { CrewRole } from "../types";

interface FilterBarProps {
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  statusFilter: CrewRole["status"] | "All";
  setStatusFilter: (filter: CrewRole["status"] | "All") => void;
}

export function FilterBar({
                     searchQuery,
                     setSearchQuery,
                     statusFilter,
                     setStatusFilter,
                   }: FilterBarProps) {
  return (
      <div className="p-4 border-b border-gray-200 flex justify-between items-center">
        <Input
            placeholder="Search roles..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-2">
              <Filter className="mr-2 h-4 w-4" />
              Filter
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem onClick={() => setStatusFilter("All")}>All</DropdownMenuItem>
            <DropdownMenuItem onClick={() => setStatusFilter("Hiring")}>Hiring</DropdownMenuItem>
            <DropdownMenuItem onClick={() => setStatusFilter("Booked")}>Booked</DropdownMenuItem>
            <DropdownMenuItem onClick={() => setStatusFilter("Confirmed")}>Confirmed</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
  );
}