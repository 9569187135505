import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './auth-provider';

const RootRedirect: React.FC = () => {
    const { isAuthenticated } = useAuth();
    
    return isAuthenticated ? <Navigate to="/productions" /> : <Navigate to="/login" />;
};

export default RootRedirect;