import { setSession } from "../components/utils";
import axiosInstance from "../services/axios";
import { Production } from "../types";

interface LoginResponse {
    token: string;
}

interface SignupRequest {
    name: string;
    adminEmail: string;
    adminFirstName: string;
    adminLastName: string;
    password: string;
}

interface SignupResponse {
    success: boolean;
    message?: string;
}

interface VerifyAccountRequest {
    username: string;
    tempPassword: string;
    newPassword: string;
}

export interface UpdateProductionRequest {
    productionId: string; // Production id
    name?: string;
    roles: {
        id?: string;
        name: string;
        department: string;
        location: string;
        budget: number;
        budgetFrequency: string;
        approximateDates: boolean;
        description: string;
        status?: string;
        startDate?: string;
        endDate?: string;
    }[];
}

export interface UpdateProductionResponse {
    id: string;
    name: string;
    company: string;
    roles: {
        id: string;
        name: string;
        job: string;
        department: string;
        approximateDates: boolean;
        location: string;
        budget: number;
        budgetFrequency: string;
        description: string;
        status: string;
        startDate: string;
        endDate: string;
    }[];
}

export const signup = async (data: SignupRequest): Promise<SignupResponse> => {
    const response = await axiosInstance.post("/accounts", data);
    return response.data;
};

export const verifyAccount = async (data: VerifyAccountRequest): Promise<boolean> => {
    const response = await axiosInstance.post("/auth/verify", data);
    return response.status === 200;
};

export const login = async (email: string, password: string): Promise<LoginResponse> => {
    const response = await axiosInstance.post("/auth/login", { email, password });

    const { token } = response.data;
    setSession(token); // Persist the token immediately

    return response.data;
};

export const logout = async (): Promise<void> => {
    // await axiosInstance.post("/auth/logout");
    setSession(null);
    window.location.href = "/login";
};

export const getAdmin = async (): Promise<any> => {
    const response = await axiosInstance.get("/admin/me");
    return response.data;
};

export const getProductions = async (): Promise<Production[]> => {
    const response = await axiosInstance.get("/productions");
    return response.data;
};

export const getProductionById = async (id: string): Promise<Production> => {
    const response = await axiosInstance.get(`/productions/${id}`);
    return response.data;
};

export const createProduction = async (productionData: any) => {
    const response = await axiosInstance.post("/productions", productionData, {
        headers: { "Content-Type": "application/json" },
    });
    return response.data;
};

export const updateProduction = async (
    data: UpdateProductionRequest
): Promise<UpdateProductionResponse> => {
    const response = await axiosInstance.put(`/productions/${data.productionId}`, data);
    return response.data;
};