
import React from "react";
import { formatDateForInput } from "../utils/dateUtils";

import { Button } from "./ui/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "./ui/Dialog";
import { Input } from "./ui/Input";
import { Label } from "./ui/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/Select";
import { Textarea } from "./ui/Textarea";
import { Switch } from "./ui/Switch";
import { CrewRole } from "../types";
import { ValidationError, validateRole } from "./validation";

interface DetailedRoleModalProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  newRole: Omit<CrewRole, "id">;
  setNewRole: React.Dispatch<React.SetStateAction<Omit<CrewRole, "id">>>;
  editingRoleId: string | null;
  onSubmit: () => void;
  onCancel: () => void;
  departments: string[];
}

export function DetailedRoleModal({
                             isOpen,
                             setIsOpen,
                             newRole,
                             setNewRole,
                             editingRoleId,
                             onSubmit,
                             onCancel,
                             departments,
                           }: DetailedRoleModalProps) {
  const [errors, setErrors] = React.useState<ValidationError[]>([]);
  
  const handleSubmit = () => {
    const validationErrors = validateRole(newRole);
    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors([]);
    onSubmit();
  };

  return (
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        
        <DialogContent className="bg-white sm:max-w-[625px]">
          <DialogHeader>
            <DialogTitle>{editingRoleId ? "Edit Role" : "Add New Role"}</DialogTitle>
            <DialogDescription>Enter the details for the role</DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="name" className="text-right">
                Job Title
              </Label>
              <Input
                  id="name"
                  value={newRole.name}
                  onChange={(e) =>
                      setNewRole({ ...newRole, name: e.target.value })
                  }
                  className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="status" className="text-right">
                Status
              </Label>
              <Select
                  value={newRole.status}
                  onValueChange={(value: CrewRole["status"]) =>
                      setNewRole({ ...newRole, status: value })
                  }
              >
                <SelectTrigger className="col-span-3">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Hiring">Hiring</SelectItem>
                  <SelectItem value="Booked">Booked</SelectItem>
                  <SelectItem value="Confirmed">Confirmed</SelectItem>
                </SelectContent>
              </Select>
            </div>
            {(newRole.status === "Booked" || newRole.status === "Confirmed") && (
                <>
                  <div className="grid grid-cols-4 items-center gap-4">
                    <Label htmlFor="personName" className="text-right">
                      Person Name
                    </Label>
                    <Input
                        id="personName"
                        value={newRole.person?.name || ""}
                        className="col-span-3"
                    />
                  </div>
                  {/* Additional fields for personEmail and personPhone can go here */}
                </>
            )}
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="startDate" className="text-right">
                Start Date
              </Label>
              <Input
                  id="startDate"
                  type="date"
                  value={formatDateForInput(newRole.startDate)}
                  onChange={(e) =>
                      setNewRole({ ...newRole, startDate: e.target.value })
                  }
                  className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="endDate" className="text-right">
                End Date
              </Label>
              <Input
                  id="endDate"
                  type="date"
                  value={formatDateForInput(newRole.endDate)}
                  onChange={(e) =>
                      setNewRole({ ...newRole, endDate: e.target.value })
                  }
                  className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="approximateDates" className="text-right">
                Approximate Dates
              </Label>
              <div className="col-span-3">
                <Switch
                    id="approximateDates"
                    checked={newRole.approximateDates}
                    onCheckedChange={(checked) =>
                        setNewRole({ ...newRole, approximateDates: checked })
                    }
                />
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="location" className="text-right">
                Location
              </Label>
              <Input
                  id="location"
                  value={newRole.location}
                  onChange={(e) =>
                      setNewRole({ ...newRole, location: e.target.value })
                  }
                  className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="budget" className="text-right">
                Budget
              </Label>
              <div className="col-span-3 flex gap-4">
                <Input
                    id="budget"
                    type="number"
                    value={newRole.budget}
                    onChange={(e) =>
                        setNewRole({ ...newRole, budget: Number(e.target.value) })
                    }
                    className="flex-1"
                />
                <Select
                    value={newRole.budgetFrequency}
                    onValueChange={(value: CrewRole["budgetFrequency"]) =>
                        setNewRole({ ...newRole, budgetFrequency: value })
                    }
                >
                  <SelectTrigger className="w-[130px]">
                    <SelectValue />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="Daily">per day</SelectItem>
                    <SelectItem value="Weekly">per week</SelectItem>
                    <SelectItem value="Hourly">per hour</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="description" className="text-right">
                Description
              </Label>
              <Textarea
                  id="description"
                  value={newRole.description}
                  onChange={(e) =>
                      setNewRole({ ...newRole, description: e.target.value })
                  }
                  className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="department" className="text-right">
                Department
              </Label>
              <Select
                  value={newRole.department || "No Department"}
                  onValueChange={(value: string) =>
                      setNewRole({
                        ...newRole,
                        department: value === "No Department" ? "" : value,
                      })
                  }
              >
                <SelectTrigger className="col-span-3">
                  <SelectValue placeholder="Select Department" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="No Department">No Department</SelectItem>
                  {departments.map((dept) => (
                      <SelectItem key={dept} value={dept}>
                        {dept}
                      </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <DialogFooter>
            <div className="w-full">
              {errors.length > 0 && (
                <div className="mb-4 p-4 bg-red-50 text-red-900 rounded-md">
                  <h4 className="font-semibold mb-2">Please fix the following errors:</h4>
                  <ul className="list-disc list-inside">
                    {errors.map((error, index) => (
                      <li key={index}>{error.message}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="flex justify-end gap-2">
                <Button variant="outline" onClick={onCancel}>
                  Cancel
                </Button>
                <Button onClick={handleSubmit}>
                  {editingRoleId ? "Save Changes" : "Add Role"}
                </Button>
              </div>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
  );
}